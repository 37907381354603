$padding: 20px;

.screen {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
    position: absolute;
    left: $padding;
    top: $padding;
    bottom: $padding;
    right: $padding;
    .empty {
        order: 0;
        flex: 1 1 auto;
        align-self: auto;
        text-align: center;
    }
}
