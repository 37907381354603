$border: 5px;
$padding: 16px;
$bg: #000;
$bg-empty: #a0a0a0;

$bg-complete: #00b000;
$bg-reserved: #00b0ff;
$bg-packing: #cee740;
$bg-returned: #ffa41b;
$bg-received: #c200a2;
$bg-parked: #717171;
$bg-missing: #ff4f4c;

.zone {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    border-radius: 5 * $border;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    margin: $padding;
    overflow: hidden;
    border: $border solid $bg;
    .zone-header {
        font-size: 40px;
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
        padding: $padding;
        background: $bg;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: stretch;
        align-items: flex-start;
        .zone-name {
            padding: 0;
            margin: 0;
            order: 0;
            flex: 2 1 auto;
            align-self: auto;
            text-align: left;
            font-weight: bold;
        }
        .zone-status {
            padding: 0;
            margin: 0;
            order: 1;
            flex: 1 1 auto;
            align-self: auto;
            text-align: right;
        }
    }
    .zone-body {
        text-align: left;
        height: 100%;
        background: #fff;
        .row {
            height: 100%;
        }
        .row > div {
            font-size: 70px;
            line-height: 70px;
        }
    }
    &.zone-small {
        .zone-header {
            font-size: 26px;
        }
        .zone-body .row > div {
            font-size: 34px;
            line-height: 34px;
        }
    }
    &.empty {
        border: $border solid $bg-empty !important;
        .zone-header {
            background: $bg-empty !important;
        }
        .zone-body {
            text-align: center;
            font-size: 66px;
            line-height: 66px;
            margin: auto 0;
            color: #ccc;
            font-weight: bold;
            padding: 1rem;
        }
    }
    &.complete {
        border: $border solid $bg-complete !important;
        .zone-header {
            background: $bg-complete !important;
        }
    }
    &.reserved {
        border: $border solid $bg-reserved !important;
        .zone-header {
            background: $bg-reserved !important;
        }
    }
    &.packing {
        border: $border solid $bg-packing !important;
        .zone-header {
            background: $bg-packing !important;
        }
    }
    &.returned {
        border: $border solid $bg-returned !important;
        .zone-header {
            background: $bg-returned !important;
        }
    }
    &.received {
        border: $border solid $bg-received !important;
        .zone-header {
            background: $bg-received !important;
        }
    }
    &.parked {
        border: $border solid $bg-parked !important;
        .zone-header {
            background: $bg-parked !important;
        }
    }
    &.missing {
        border: $border solid $bg-missing !important;
        .zone-header {
            background: $bg-missing !important;
        }
    }
}
